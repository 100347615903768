import React from "react"
import { ErrorBoundary } from "../components/errors"
import Layout from "../components/layout"
import { Playlists } from "../components/playlists"
import { Providers } from "../components/providers"
import SEO from "../components/seo"
import { SelectedProviderContext } from "../context/selected-provider-context"
import { providers } from "../data/playlist-data"
import playlists from "./../data/playlist-data.json"
import { usePersistedState } from "../hooks/usePersistedState"

const IndexPage = () => {
  const [selectedProvider, setSelectedProvider] = usePersistedState(
    "provider",
    null
  )
  return (
    <ErrorBoundary>
      <Layout>
        <SEO title="JAMZ ONLY Playlists" />

        <SelectedProviderContext.Provider value={selectedProvider}>
          <Providers
            providers={providers}
            handleProviderClick={setSelectedProvider}
          />

          {selectedProvider && <Playlists playlists={playlists} />}
        </SelectedProviderContext.Provider>
      </Layout>
    </ErrorBoundary>
  )
}

export default IndexPage
